import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  { path: 'standalone', loadChildren: () => import('./standalone/standalone.module').then(m => m.StandaloneModule) },
  { path: '', loadChildren: () => import('./web/web.module').then(m => m.WebModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
